import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import Cookies from 'js-cookie';
import FormTextInput from './form_text_input.jsx';
import FormErrors from './form_errors.jsx';
import SubmitButton from './submit_button.jsx';
import {Link, useLocation} from 'react-router-dom';
import authRoutes from '../auth_routes.js';
import {initTooltips} from 'styledot/tooltip';


const MfaTotpForm = (props) => {
    const [tokenError, setTokenError] = useState();
    const [successMsg, setSuccessMsg] = useState();
    const [userToken, setUserToken] = useState();
    const [rememberDevice, setRememberDevice] = useState();
    const hasErrors = (errors) => {
        return errors && errors.length > 0;
    };
    useEffect(() => {
        initTooltips(['mfa-totp-info-icon']);
    }, []);
    const mfaTypeToInstructions = {
        'email_otp': <>An email with a secure verification code was sent to the email address
        associated with your 23andMe account. </>,
        'mfa_totp': 'Enter the verification code generated by your mobile application.',
        'default': 'Enter the verification code generated by your mobile application.',
    };
    let isEmailOTP = false;
    let mfaInstructions = mfaTypeToInstructions['default'];
    if (props?.mfa_type) {
        mfaInstructions = mfaTypeToInstructions[props.mfa_type];
        isEmailOTP = props.mfa_type === 'email_otp';
    }
    const currLocation = useLocation();
    const onSubmit = (e) => {
        setSuccessMsg(null);
        e.preventDefault();
        if (!userToken) {
            setTokenError('You must provide a verification code.');
            return false;
        }
        const currentNext = window.location.search;
        const payload = {
            'user_token': userToken,
            'remember_device': rememberDevice,
        };
        return $.post({
            url: `/mfa_totp/${currentNext}`,
            data: payload,
            headers: {
                'X-CSRFToken': Cookies.get('csrftoken'),
            },
        }).then((data) => {
            window.location.href = data.url;
        }).fail((data) => {
            if (!data.responseJSON) {
                window.location.href = '/login/';
            }
            const redirectUrl = data.responseJSON.url;
            const returnedNext = encodeURIComponent(data.responseJSON.next);
            if (data.responseJSON.retriesError ||
                data.responseJSON.timeoutError) {
                // Redirect directly to login with next
                window.location.href = `${redirectUrl}${returnedNext}`;
            } else if (data.responseJSON.tokenError) {
                setTokenError(data.responseJSON.tokenError);
            } else {
                setTokenError('An error occurred. Please try again later.');
            }
        });
    };
    const onRequestResend = (e) => {
        e.preventDefault();
        return $.get({
            url: '/mfa_email_resend/',
        }).then((data) => {
            setTokenError(null);
            setSuccessMsg('We emailed you a new code.');
        }).fail((data) => {
            setTokenError('An error has occurred');
            setSuccessMsg(null);
        });
    };

    return (
        <div className='auth-narrow mfa-container'>
            <div className='sd-card sd-elevation-1 auth-card'>
                <div>
                    <img
                        className='mfa-totp-device-icon hide-for-desktop'
                        alt=''
                        src='/app/auth/static/img/icons/phone_and_lock.svg'
                    />
                    <img
                        className='mfa-totp-device-icon hide-for-mobile hide-for-tablet'
                        alt=''
                        src='/app/auth/static/img/icons/laptop.svg'
                    />
                </div>

                <h1>
                    {isEmailOTP ? 'Enter verification code' : (
                        <>2-Step Verification
                            <button className='sd-unbutton mfa-totp-info-icon'
                                data-tippy-content='Need help with 2-step verification? Visit our
        <a href="https://customercare.23andme.com/hc/en-us/articles/360034119874">help article</a>,
        or <a href="https://customercare.23andme.com"">contact Customer Care</a>.'>
                                <svg className='mfa-totp-info-icon-img' focusable='false' aria-hidden='true'>
                                    <use xlinkHref='/app/auth/static/img/icons/ic_info.svg#icon' />
                                </svg>
                                <span className='visually-hidden'>more info</span>
                            </button>
                        </>
                    )}
                </h1>
                <form method='post'>
                    <FormErrors
                        errors={tokenError}
                        classes='auth-error non-field-error'
                    />
                    {successMsg && (
                        <p className='success-text'>
                            {successMsg}
                        </p>
                    )}
                    <div className='mfa-totp-input-instruction-container mfa-totp-instructions-container'>
                        <p className='mfa-totp-instructions-text'>
                            {mfaInstructions}
                        </p>
                    </div>
                    <FormTextInput
                        autofocus={true}
                        label='Verification Code'
                        name='token'
                        isInvalid={hasErrors(tokenError)}
                        onChange={(e) => {
                            setUserToken(e.target.value);
                        }}
                        mdvId='auth-mfa-token'
                        autocomplete={false}
                    />
                    <div className='mfa-remember-device'>
                        <input
                            id='remember_device_checkbox'
                            type='checkbox'
                            checked={rememberDevice}
                            name='remember_device'
                            value='checkbox_default'
                            className='sd-form-checkbox'
                            onChange={(e) => {
                                setRememberDevice(e.target.checked);
                            }}
                        />
                        <label
                            htmlFor='remember_device_checkbox'
                            className='sd-form-label tos-label'
                        >
                            <span>
                                Remember me
                            </span>
                        </label>
                    </div>
                    <SubmitButton
                        buttonId='mfa-verify-button'
                        mdvId='auth-mfa-token-submit'
                        onClick={onSubmit}
                        className='sd-button mod-button-wide mfa-verify-button'
                        type='submit'
                        role='button'
                        value='Verify'
                    >
                        Verify
                    </SubmitButton>
                </form>
                {isEmailOTP ? (
                    <>
                        <p>
                            Didn&apos;t receive a code? <button
                                className='sd-button-anchor email-mfa-resend-button'
                                onClick={onRequestResend}>
                                Request a new code.
                            </button>
                        </p>
                        <p>
                            Having trouble? Contact <a href='https://customercare.23andme.com'>Customer Care</a>
                        </p>
                    </>
                ): (
                    <p>
                        <Link to={{pathname: authRoutes.AUTH_ROUTES.MFA_RESET, search: currLocation.search}}>
                            Can&apos;t access your authenticator app?
                        </Link>
                    </p>
                )}
            </div>
        </div>
    );
};

MfaTotpForm.propTypes = {
    mfa_type: PropTypes.string,
};

MfaTotpForm.defaultProps = {};

export default MfaTotpForm;
