import React, {useState} from 'react';
import {getDaysInMonth as getDaysInMonthFns, isExists} from 'date-fns';
import range from 'lodash/range';
import PropTypes from 'prop-types';
import FormSelect from './form_select.jsx';

// Pick random non-leap year default
const defaultYear = 2001;
const monthsOptions = [
    {value: '1', name: 'Jan'},
    {value: '2', name: 'Feb'},
    {value: '3', name: 'Mar'},
    {value: '4', name: 'Apr'},
    {value: '5', name: 'May'},
    {value: '6', name: 'Jun'},
    {value: '7', name: 'Jul'},
    {value: '8', name: 'Aug'},
    {value: '9', name: 'Sep'},
    {value: '10', name: 'Oct'},
    {value: '11', name: 'Nov'},
    {value: '12', name: 'Dec'},
];

const FormDobSelect = (props) => {
    const [dobMonth, setDobMonth] = useState();
    const [dobDay, setDobDay] = useState();
    const [dobYear, setDobYear] = useState();
    const year = new Date().getFullYear();
    // No one is older than 1900, surely
    const yearOptions = range(year, 1900, -1).map((y) => {
        return {name: y, value: String(y)};
    });

    const getDaysInMonth = (monthVal) => {
        const upper = monthVal ? getDaysInMonthFns(new Date(dobYear || defaultYear, monthVal - 1)) : 31;
        return range(1, upper + 1);
    };

    const dayIsValidForMonth = (monthVal, dayVal) => {
        return !dayVal || isComplete(monthVal, dayVal, dobYear || defaultYear);
    };

    const dayOptions = getDaysInMonth(dobMonth).map((d) => {
        return {name: d.toString().padStart(2, '0'), value: d.toString()};
    });

    const isComplete = (monthVal, dayVal, yearVal) => {
        return monthVal && dayVal && yearVal && isExists(yearVal, monthVal - 1, dayVal);
    };

    return (
        <div>
            <p className='auth-shorttext mod-dob'>
                Date of birth&nbsp;
                <svg
                    width='13'
                    height='10'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <path
                        d={'M5.2 9.4a1 1 0 0 1-.8-.3L1.2 5.8a1 1 0 0 1 1.5-1.5l2.5 2.5L11 1a1 1 0 1 1 1.5 1.5L6 ' +
                           '9.1a1 1 0 0 1-.8.3z'}
                        fill='#FFF'
                    />
                </svg>
            </p>

            <div className={`auth-create-select ${props.isInvalid ? 'mod-invalid' : ''}`}>
                <FormSelect
                    id='dob-month'
                    options={monthsOptions}
                    placeholder='Month'
                    label='Birth month'
                    required={props.required}
                    onChange={(e) => {
                        const newMonth = e.target.value ? parseInt(e.target.value) : null;
                        setDobMonth(newMonth);
                        props.onChange({
                            day: dobDay,
                            month: newMonth,
                            year: dobYear,
                            isComplete: isComplete(newMonth, dobDay, dobYear),
                        });
                    }}
                />

                <FormSelect
                    id='dob-day'
                    options={dayOptions}
                    isInvalid={!dayIsValidForMonth(dobMonth, dobDay)}
                    placeholder='Day'
                    label='Birth day'
                    required={props.required}
                    onChange={(e) => {
                        const newDay = e.target.value ? parseInt(e.target.value) : null;
                        setDobDay(newDay);
                        props.onChange({
                            day: newDay,
                            month: dobMonth,
                            year: dobYear,
                            isComplete: isComplete(dobMonth, newDay, dobYear),
                        });
                    }}
                />

                <FormSelect
                    id='dob-year'
                    options={yearOptions}
                    placeholder='Year'
                    label='Birth year'
                    required={props.required}
                    onChange={(e) => {
                        const newYear = e.target.value ? parseInt(e.target.value) : null;
                        setDobYear(newYear);
                        props.onChange({
                            day: dobDay,
                            month: dobMonth,
                            year: newYear,
                            isComplete: isComplete(dobMonth, dobDay, newYear),
                        });
                    }}
                />
            </div>
        </div>
    );
};

FormDobSelect.propTypes = {
    // onChange is of the form onChange({month, day, year, isComplete})
    // month: the integer value for the month - 1 indexed
    // day: the integer value for the day - 1 indexed
    // year: the integer value for the year - 0 indexed
    // isCompleted: true if the month / day / year combination is complete and valid. otherwise false
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    isInvalid: PropTypes.bool,
};

FormDobSelect.defaultProps = {
    required: false,
    isInvalid: false,
};

export {FormDobSelect};
