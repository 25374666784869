import React from 'react';
import PropTypes from 'prop-types';

const TOSEmailOptInForm = (props) => {
    const handleTOSCheckbox = (e) => {
        props.handleTosAction(e.target.checked);
    };
    const handleEmailOptInCheckbox = (e) => {
        props.handleEmailOptIn(e.target.checked);
    };

    return (
        <div>
            <div className='auth-create-consent sd-form-field'>
                <input
                    id='id_tos'
                    type='checkbox'
                    checked={props.acceptTOS}
                    name='tos'
                    value='checkbox_default'
                    className={`sd-form-checkbox tos-input ${props.isInvalid ? 'mod-invalid' : ''}`}
                    required
                    onChange={handleTOSCheckbox}
                />
                <label
                    id='tos_label'
                    htmlFor='id_tos'
                    className='sd-form-label tos-label'
                >
                    <span className={'tos-text'}>
                        I have read and agree to the{' '}
                        <a
                            id='tos-link'
                            href={`https://www.23andme.com/${props.localizedTOS}/legal/terms-of-service/`}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Terms of Service
                        </a>
                        {' '}and{' '}
                        <a
                            href='https://www.23andme.com/legal/privacy/'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Privacy Statement
                        </a>.
                    </span>
                </label>
            </div>
            {props.showEmailOptIn && (
                <div className='auth-create-consent sd-form-field'>
                    <input
                        id='id_email_optin'
                        type='checkbox'
                        name='email_optin'
                        value='checkbox_default'
                        onChange={handleEmailOptInCheckbox}
                        checked={props.emailOptIn}
                        className='sd-form-checkbox'
                    />
                    <label
                        id='email_optin_label'
                        htmlFor='id_email_optin'
                        className='sd-form-label tos-label'
                    >
                        Yes, please send me 23andMe product and promotional emails.
                        You can unsubscribe at any time.
                    </label>
                </div>
            )}
        </div>
    );
};


TOSEmailOptInForm.propTypes = {
    showEmailOptIn: PropTypes.bool.isRequired,
    localizedTOS: PropTypes.string.isRequired,
    handleTosAction: PropTypes.func,
    acceptTOS: PropTypes.bool,
    emailOptIn: PropTypes.bool,
    handleEmailOptIn: PropTypes.func,
    isInvalid: PropTypes.bool,
};

TOSEmailOptInForm.defaultProps = {
    handleTosAction: null,
    acceptTOS: false,
    emailOptIn: false,
    handleEmailOptIn: null,
    isInvalid: false,
};

export default TOSEmailOptInForm;
