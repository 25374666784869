import React from 'react';
import PropTypes from 'prop-types';
import {AppBanner} from './app_banner.jsx';
import {SignUpForm} from './signup_form.jsx';
import {getSource} from '../title_sources';

const TtamSignup = (props) => {
    return (
        <div>
            {props.showAppDownload && (
                <AppBanner
                    starIconUrl={props.starIconUrl}
                    closeIconUrl={props.closeIconUrl}
                    appStoreUrlIconUrl={props.appStoreImgUrl}
                    googlePlayStoreIconUrl={props.googlePlayImgUrl}
                />
            )}
            <div className={`auth-narrow ${props.showKitImage ? 'auth-kitreg-create' : ''}`}>
                <div className='auth-kit'>
                    {props.showKitImage && (
                        <img className='auth-kit-img' src={props.kitImgUrl} alt='Spit Kit' />
                    )}
                </div>
                <div className='auth-create-wrapper'>
                    <div className='sd-card sd-elevation-1 auth-card'>
                        <SignUpForm
                            title={getSource().title}
                            subtext={getSource().subtext}
                            googleImgUrl={props.googleImgUrl}
                            appleImgUrl={props.appleImgUrl}
                            onSuccessfulSubmit={props.onSuccessfulSubmit}
                            onGoogleSsoClick={props.onGoogleSsoClick}
                            googleSsoButtonEnabled={props.googleSsoButtonEnabled}
                            onAppleSsoClick={props.onAppleSsoClick}
                            localizedTOS={props.localizedTOS}
                            showEmailOptIn={props.showEmailOptIn}
                            submitUrl='/signup/'
                            submitButtonText='Create Account'
                            isSso={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};


TtamSignup.propTypes = {
    onGoogleSsoClick: PropTypes.func.isRequired,
    onAppleSsoClick: PropTypes.func.isRequired,
    googleSsoButtonEnabled: PropTypes.bool.isRequired,
    onSuccessfulSubmit: PropTypes.func.isRequired,
    showEmailOptIn: PropTypes.bool.isRequired,
    localizedTOS: PropTypes.string.isRequired,
    googleImgUrl: PropTypes.string.isRequired,
    appleImgUrl: PropTypes.string.isRequired,
    starIconUrl: PropTypes.string.isRequired,
    closeIconUrl: PropTypes.string.isRequired,
    appStoreImgUrl: PropTypes.string.isRequired,
    googlePlayImgUrl: PropTypes.string.isRequired,
    kitImgUrl: PropTypes.string.isRequired,
    showAppDownload: PropTypes.bool,
    showKitImage: PropTypes.bool,
};

TtamSignup.defaultProps = {
    showAppDownload: false,
    showKitImage: false,
};

export {TtamSignup};
