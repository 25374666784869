const loadScript = (src) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', resolve);
        script.addEventListener('error', reject);
        document.body.appendChild(script);
    });
};

const APPLE_SSO_SCRIPT = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
const GOOGLE_SSO_SCRIPT = 'https://apis.google.com/js/client:platform.js';

const exportFuncs = {loadScript, APPLE_SSO_SCRIPT, GOOGLE_SSO_SCRIPT};

export default exportFuncs;
