import React, {useEffect, useState} from 'react';
import FormErrors from './form_errors.jsx';
import SubmitButton from './submit_button.jsx';
import {initTooltips} from 'styledot/tooltip';
import $ from 'jquery';
import PropTypes from 'prop-types';


const MfaTotpReset = (props) => {
    const [errors, setErrors] = useState();
    const onSubmit = (e) => {
        e.preventDefault();
        return $.post({
            url: '/mfa_reset_send/',
        }).then((data) => {
            props.onSuccessfulSubmit(data);
        }).fail((data, _, status) => {
            if (status === 'Forbidden') {
                setErrors('An error occurred, please refresh the page to login again.');
                return;
            }
            if (!data.responseJSON) {
                setErrors('An error occurred.');
                return;
            }
            if (data.responseJSON.error) {
                setErrors(data.responseJSON.error);
            } else {
                setErrors('An error occurred.');
            }
        });
    };


    useEffect(() => {
        initTooltips(['mfa-totp-info-icon']);
    }, []);


    return (
        <div className='auth-narrow'>
            <div className='sd-card sd-elevation-1 auth-card'>
                <h1>
                    2-Step Verification
                    <button className='sd-unbutton mfa-totp-info-icon'
                        data-tippy-content='Need help with 2-step verification? Visit our
<a href="https://customercare.23andme.com/hc/en-us/articles/360034119874">help article</a>,
or <a href="https://customercare.23andme.com">contact Customer Care</a>.'>
                        <svg className='mfa-totp-info-icon-img' focusable='false' aria-hidden='true'>
                            <use xlinkHref='/app/auth/static/img/icons/ic_info.svg#icon' />
                        </svg>
                        <span className='visually-hidden'>more info</span>
                    </button>
                </h1>
                <form method='post'>
                    <FormErrors
                        classes='auth-error non-field-error'
                    />
                    <div className='mfa-totp-instructions-container'>
                        <img
                            className='mfa-totp-device-icon'
                            alt=''
                            src='/app/auth/static/img/icons/phone.svg'
                        />
                        <p className='mfa-totp-instructions-text'>
                            <p>
                                We are able to email a one-click login link to your account
                                email address. <strong>This will disable 2-step verification
                                within your account.</strong>
                            </p>
                            <p>Note that this link will expire in 15 minutes.</p>
                        </p>
                    </div>
                    <FormErrors errors={errors} />
                    <SubmitButton
                        buttonId='mfa-reset-button'
                        onClick={onSubmit}
                        className='sd-button mod-button-wide mfa-verify-button'
                        type='submit'
                        role='button'
                    >
                        Email me a link
                    </SubmitButton>
                </form>
            </div>
        </div>
    );
};

MfaTotpReset.propTypes = {
    onSuccessfulSubmit: PropTypes.func.isRequired,
};

MfaTotpReset.defaultProps = {};

export default MfaTotpReset;
