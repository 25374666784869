import React, {useState, useLayoutEffect} from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import {useLocation} from 'react-router-dom';
import FormTextInput from './form_text_input.jsx';
import FormErrors from './form_errors.jsx';
import SubmitButton from './submit_button.jsx';


const MfaResetSignInForm = (props) => {
    const [preLoginErrors, setPreLoginErrors] = useState();
    const [passwordErrors, setPasswordErrors] = useState();
    const [userPassword, setUserPassword] = useState('');
    const currLocation = useLocation();
    const searchParams = new URLSearchParams(currLocation.search);

    useLayoutEffect(() => {
        $.get({
            url: '/login/',
        }).done((data) => {
            if (data.preLoginErrors) {
                setPreLoginErrors(data.preLoginErrors);
            }
        });
    }, []);

    const hasErrors = (errors) => {
        return errors && errors.length > 0;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (!userPassword) {
            setPasswordErrors('Please enter your password');
            return false;
        }
        const payload = {
            password: userPassword,
            uidb64: searchParams.get('uidb64'),
            token: searchParams.get('token'),
        };
        return $.post({
            url: '/mfa_reset_confirm/',
            data: payload,
        }).done((data) => {
            window.location.href = data.url;
        }).fail((data) => {
            if (!data.responseJSON) {
                return;
            }
            if (data.responseJSON.preLoginErrors) {
                setPreLoginErrors(data.responseJSON.preLoginErrors);
            }
            if (data.responseJSON.errors) {
                const errors = data.responseJSON.errors;
                if (errors[0] == 'bad token') {
                    props.onBadToken();
                }
                setPasswordErrors(errors[0]);
            }
        });
    };
    return (
        <div id='sign-in-form'>
            <h1>Disable 2-Step Verification</h1>
            <p>
                Enter your 23andMe account password below:
            </p>
            <form
                method='post'
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <FormErrors
                    errors={preLoginErrors}
                    classes='auth-error non-field-error'
                    isMdv={true}
                />
                <FormTextInput
                    label='Password'
                    name='password'
                    type='password'
                    value={userPassword}
                    isInvalid={hasErrors(passwordErrors)}
                    mdvId='auth-password'
                    onChange={
                        (e) => {
                            setUserPassword(e.target.value);
                            setPasswordErrors([]);
                        }
                    }
                />
                <FormErrors
                    errors={passwordErrors}
                    classes='auth-error'
                    isMdv={true}
                />
                <p>
                    Having trouble? Contact <a href='https://customercare.23andme.com'>Customer Care</a>.
                </p>
                <SubmitButton
                    buttonId='sign-in-button'
                    onClick={onSubmit}
                    mdvId='auth-signin'
                    className='sd-button auth-login-submit mod-button-wide'
                >
                    Disable
                </SubmitButton>
            </form>
        </div>
    );
};

MfaResetSignInForm.propTypes = {
    onBadToken: PropTypes.func.isRequired,
};

MfaResetSignInForm.defaultProps = {};

export default MfaResetSignInForm;
