import React from 'react';
import PropTypes from 'prop-types';

const VerifyEmail = (props) => {
    if (!props.email) {
        props.anonUserRedirect();
    }
    return (
        <div>
            <div>
                <h1>Verify your email</h1>
                <p>
                    We just sent an email to <strong>{props.email}</strong>.
                </p>
                <p>
                    If you don&apos;t see a message in your inbox, make sure the
                    email address listed above is correct and check your spam
                    or junk mail folder. This email is sent from donotreply@23andme.com.
                </p>
                <p>
                    If you want us to resend the email, <a href='/resend_confirmation_email_done/'>click here</a>
                </p>
                <p className='mod-last-p'>
                    For further questions,&nbsp;
                    <a href='https://customercare.23andme.com' title='Contact Customer Care'>
                        contact Customer Care
                    </a>.
                </p>
            </div>
        </div>
    );
};

VerifyEmail.propTypes = {
    email: PropTypes.string,
    anonUserRedirect: PropTypes.func.isRequired,
};

export {VerifyEmail};
