import React, {useState, useLayoutEffect} from 'react';
import $ from 'jquery';
import {useLocation} from 'react-router-dom';
import FormTextInput from './form_text_input.jsx';
import FormErrors from './form_errors.jsx';
import SubmitButton from './submit_button.jsx';


const ConfirmAccountSignInForm = (props) => {
    const [preLoginErrors, setPreLoginErrors] = useState();
    const [passwordErrors, setErrors] = useState();
    const [userPassword, setUserPassword] = useState('');
    // true when a mobile user has successfully confirmed their account
    const [mobileSuccess, setMobileSuccess] = useState(false);
    const currLocation = useLocation();
    const searchParams = new URLSearchParams(currLocation.search);

    useLayoutEffect(() => {
        $.get({
            url: '/login/',
        }).done((data) => {
            if (data.preLoginErrors) {
                setPreLoginErrors(data.preLoginErrors);
            }
        });
    }, []);

    const hasErrors = (errors) => {
        return errors && errors.length > 0;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (!userPassword) {
            setErrors('Please enter your password');
            return false;
        }
        const payload = {
            password: userPassword,
            uidb64: searchParams.get('uidb64'),
            token: searchParams.get('token'),
            redirect_uri: searchParams.get('redirect_uri'),
        };
        return $.post({
            url: '/confirm_account_signin/',
            data: payload,
        }).done((data) => {
            if (searchParams.get('mobile') == 1) {
                setMobileSuccess(true);
            } else {
                window.location.href = data.url;
            }
        }).fail((data) => {
            if (!data.responseJSON) {
                return;
            }
            if (data.responseJSON.preLoginErrors) {
                setPreLoginErrors(data.responseJSON.preLoginErrors);
            }
            if (data.responseJSON.errors) {
                const errors = data.responseJSON.errors;
                setErrors(errors[0]);
            }
        });
    };
    if (mobileSuccess) {
        return (
            <div>
                <h1>Your account has been confirmed.</h1>
                <p>Please return to the 23andMe app.</p>
            </div>
        );
    }
    return (
        <div id='sign-in-form'>
            <h1>Finish confirming your email address</h1>
            <p>
                Enter your 23andMe account password:
            </p>
            <form
                method='post'
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <FormErrors
                    errors={preLoginErrors}
                    classes='auth-error non-field-error'
                    isMdv={true}
                />
                <FormTextInput
                    label='Password'
                    name='password'
                    type='password'
                    value={userPassword}
                    isInvalid={hasErrors(passwordErrors)}
                    mdvId='auth-password'
                    onChange={
                        (e) => {
                            setUserPassword(e.target.value);
                            setErrors([]);
                        }
                    }
                />
                <FormErrors
                    errors={passwordErrors}
                    classes='auth-error'
                    isMdv={true}
                />
                <p>
                    Having trouble? Contact <a href='https://customercare.23andme.com'>Customer Care</a>.
                </p>
                <SubmitButton
                    buttonId='sign-in-button'
                    onClick={onSubmit}
                    mdvId='auth-signin'
                    className='sd-button auth-login-submit mod-button-wide'
                >
                    Confirm
                </SubmitButton>
            </form>
        </div>
    );
};

ConfirmAccountSignInForm.propTypes = {
};

ConfirmAccountSignInForm.defaultProps = {};

export default ConfirmAccountSignInForm;
