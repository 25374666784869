import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {button, stop} from 'styledot/loading';

/**
 * Smart submit button that greys out and shows loading animation automatically.
 * Can be manually overridden with props.enabled.
 *
 * To use the smart behavior, the onclick should return
 * false to reenable the button, or a promise, eg:
    return $.post("/stuff/", (data) => {
        ...
    }).fail((data) => {
        ...
    });
 */
const SubmitButton = (props) => {
    const [enableButton, setEnableButton] = useState(true);

    useEffect(() => {
        button(document.querySelector(`#${props.buttonId}`), null, 'gray');
    });

    const isEnabled = () => {
        if (props.enabled != undefined) {
            if (props.enabled != enableButton) {
                if (props.enabled) {
                    reenableButton();
                }
                setEnableButton(props.enabled);
            }
            return props.enabled;
        }
        return enableButton;
    };

    // Re-enable the greyed out loading button
    const reenableButton = () => {
        const submitButton = document.querySelector(`#${props.buttonId}`);
        if (submitButton) {
            setEnableButton(true);
            stop(submitButton);
        }
    };

    const suppressPopupClosedError = (err) => {
        if (err && err.error !== 'popup_closed_by_user') {
            throw err;
        }
    };

    const onClick = (e) => {
        const resp = props.onClick(e);
        const reenableOnFailedPromise = (err) => {
            reenableButton();
            suppressPopupClosedError(err);
        };
        if (resp === undefined) {
            return;
        } else if (resp === false) {
            reenableButton();
        } else if (resp.then) { // resp is promise-like (promise, XHR, etc)
            setEnableButton(false);
            if (props.reenableOnSuccess) {
                resp.then(reenableButton).catch(reenableOnFailedPromise);
            } else {
                resp.catch(reenableOnFailedPromise);
            }
        }
    };
    return (
        <button
            className={props.className} data-badge={props.badge}
            name={props.name} data-mdv-id={props.mdvId}
            type='submit' id={props.buttonId} disabled={!isEnabled()}
            data-track-click={props.gtmTrackClick}
            onClick={onClick}
        >
            {props.children}
        </button>
    );
};


SubmitButton.propTypes = {
    buttonId: PropTypes.string.isRequired,
    onClick: PropTypes.func, // to auto manage button enable, return a Promise or jqXHR obj
    enabled: PropTypes.bool, // manual button enable override
    className: PropTypes.string,
    badge: PropTypes.string,
    name: PropTypes.string,
    mdvId: PropTypes.string,
    gtmTrackClick: PropTypes.string,
    reenableOnSuccess: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
};

SubmitButton.defaultProps = {
    reenableOnSuccess: false,
};

export default SubmitButton;
