import $ from 'jquery';
import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import FormTextInput from './form_text_input.jsx';
import FormErrors from './form_errors.jsx';
import TOSEmailOptInForm from './tos_email_optin.jsx';
import SubmitButton from './submit_button.jsx';
import {FormDobSelect} from './form_dob_select.jsx';
import {SsoButtons} from './sso_buttons.jsx';
import {onSsoClickDecorator} from '../utils.jsx';
import {SignInLink} from './signup_footer.jsx';

/*
 * This is a base form that is used for generic TTAM sign up and sign up with SSO.
 */
const SignUpForm = (props) => {
    const currLocation = useLocation();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [password1, setPassword1] = useState();
    const [password2, setPassword2] = useState();
    const [birthdate, setBirthdate] = useState('');
    const [isDobComplete, setIsDobComplete] = useState(false);
    const [acceptedTos, setAcceptedTos] = useState(false);
    const [acceptedEmailOptInSelection, setAcceptedEmailOptInSelection] = useState(false);

    const [firstNameErrors, setFirstNameErrors] = useState();
    const [lastNameErrors, setLastNameErrors] = useState();
    const [emailErrors, setEmailErrors] = useState();
    const [password1Errors, setPassword1Errors] = useState();
    const [password2Errors, setPassword2Errors] = useState();
    const [nonFieldErrors, setNonFieldErrors] = useState();
    const [dobErrors, setDobErrors] = useState();
    const [tosErrors, setTosErrors] = useState();
    const searchParams = new URLSearchParams(currLocation.search);

    const hasErrors = (errors) => {
        return errors && errors.length > 0;
    };
    useEffect(() => {
        // hack onChange handlers to fire when default values for firstName and lastName are set
        // https://github.com/facebook/react/issues/11488#issuecomment-347775628
        [
            {id: 'id_first_name', stateVal: firstName},
            {id: 'id_last_name', stateVal: lastName},
        ].forEach(({id, stateVal}) => {
            const input = document.getElementById(id);
            if (input.value !== stateVal) {
                const event = new Event('input', {bubbles: true});
                event.simulated = true;
                const tracker = input._valueTracker;
                if (tracker) {
                    tracker.setValue(stateVal);
                }
                input.dispatchEvent(event);
            }
        });
    }, []);

    const validateForSubmit = () => {
        let isValidSubmitState = true;
        const requiredFields = [
            {value: firstName, errorFn: setFirstNameErrors},
            {value: lastName, errorFn: setLastNameErrors},
            {value: isDobComplete, errorFn: setDobErrors, errorText: 'Please enter a valid date.'},
            {value: acceptedTos, errorFn: setTosErrors, errorText: 'Please accept the terms of service.'},
        ];
        if (!props.isSso) {
            requiredFields.push(...[
                {value: email, errorFn: setEmailErrors},
                {value: password1, errorFn: setPassword1Errors},
                {value: password2, errorFn: setPassword2Errors},
            ]);
        }
        requiredFields.forEach(({value, errorFn, errorText}) => {
            if (!value) {
                errorFn(errorText || 'This field is required.');
                isValidSubmitState = false;
            }
        });
        return isValidSubmitState;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setEmailErrors([]);
        if (!validateForSubmit()) {
            return false;
        }
        const redirectUri = searchParams.get('next') || searchParams.get('redirect_uri');
        const payload = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            password1: password1,
            password2: password2,
            tos: acceptedTos,
            birthdate: birthdate,
            email_optin: acceptedEmailOptInSelection,
            redirect_uri: redirectUri,
        };
        return $.post({
            url: `${props.submitUrl}${currLocation.search}`,
            data: payload,
        }).done((response) => {
            props.onSuccessfulSubmit(response);
        }).fail((err) => {
            if (!err.responseJSON || !err.responseJSON.errors) {
                setNonFieldErrors('An error occurred. Please try again later.');
                return;
            }
            const errors = err.responseJSON.errors;
            const errorClasses = [
                {key: 'first_name', func: setFirstNameErrors},
                {key: 'last_name', func: setLastNameErrors},
                {key: 'email', func: setEmailErrors},
                {key: 'password1', func: setPassword1Errors},
                {key: 'password2', func: setPassword2Errors},
                {key: 'birthdate', func: setDobErrors},
                {key: 'tos', func: setTosErrors},
                {key: '__all__', func: setNonFieldErrors},
            ];
            errorClasses.forEach(({key, func}) => {
                func(errors[key]);
            });
        });
    };

    const renderGenericSignUp = () => {
        if (props.isSso) {
            return null;
        }
        return (
            <>
                <FormTextInput
                    name='email'
                    label='Email'
                    type='email'
                    onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailErrors(null);
                    }}
                    isInvalid={hasErrors(emailErrors)}
                />
                <FormErrors errors={emailErrors} classes='auth-error' isMdv={true} />

                <FormTextInput
                    name='password1'
                    type='password'
                    label='Create a password'
                    onChange={(e) => {
                        setPassword1(e.target.value);
                        setPassword1Errors(null);
                    }}
                    isInvalid={hasErrors(password1Errors)}
                />
                <FormErrors errors={password1Errors} classes='auth-error' isMdv={true} />

                <FormTextInput
                    name='password2'
                    type='password'
                    label='Confirm password'
                    onChange={(e) => {
                        setPassword2(e.target.value);
                        setPassword2Errors(null);
                    }}
                    isInvalid={hasErrors(password2Errors)}
                />
                <FormErrors errors={password2Errors} classes='auth-error' isMdv={true} />
            </>
        );
    };

    return (
        <div>
            <h1>
                {props.title}
            </h1>
            {props.subtext && (
                <p>{props.subtext}</p>
            )}
            <SignInLink styling={'signin-link'} />
            <form
                method='post'
                id='create-account-form'
                className='create-account-form'
            >
                <FormErrors errors={nonFieldErrors} className='auth-errors' isMdv={true} />

                <FormTextInput
                    autofocus={true}
                    name='first_name'
                    label='First name'
                    defaultValue={props.firstName}
                    onChange={(e) => {
                        setFirstName(e.target.value);
                        setFirstNameErrors(null);
                    }}
                    isInvalid={hasErrors(firstNameErrors)}
                />
                <FormErrors
                    errors={firstNameErrors}
                    classes='auth-error'
                    isMdv={true}
                />

                <FormTextInput
                    name='last_name'
                    label='Last name'
                    defaultValue={props.lastName}
                    onChange={(e) => {
                        setLastName(e.target.value);
                        setLastNameErrors(null);
                    }}
                    isInvalid={hasErrors(lastNameErrors)}
                />
                <FormErrors errors={lastNameErrors} classes='auth-error' isMdv={true} />

                {renderGenericSignUp()}

                <FormDobSelect
                    onChange={({month, day, year, isComplete}) => {
                        const processDateVal = (val) => {
                            return (val) ? val.toString().padStart(2, '0') : '';
                        };
                        setBirthdate(`${processDateVal(month)}/${processDateVal(day)}/${year}`);
                        setDobErrors(null);
                        setIsDobComplete(isComplete);
                    }}
                    isInvalid={hasErrors(dobErrors)}
                />
                <FormErrors errors={dobErrors} classes='auth-error' isMdv={true} />

                <TOSEmailOptInForm
                    handleTosAction={(accepted) => {
                        setAcceptedTos(accepted);
                        setTosErrors(null);
                    }}
                    acceptTOS={acceptedTos}
                    handleEmailOptIn={(accepted) => {
                        setAcceptedEmailOptInSelection(accepted);
                    }}
                    emailOptIn={acceptedEmailOptInSelection}
                    showEmailOptIn={props.showEmailOptIn}
                    localizedTOS={props.localizedTOS}
                    isInvalid={hasErrors(tosErrors)}
                />
                <FormErrors errors={tosErrors} classes='auth-error' isMdv={true} />

                <SubmitButton
                    onClick={onSubmit}
                    className='auth-create-account-submit sd-button mod-button-wide sd-loading-button'
                    badge='inline'
                    name='btnSubmit'
                    buttonId='btnsubmit'
                    gtmTrackClick='auth_create_account_button'
                    mdvId='auth-create-account-submit-button'
                >
                    {props.submitButtonText}
                </SubmitButton>
            </form>
            {!props.isSso && (
                <SsoButtons
                    appleImgUrl={props.appleImgUrl}
                    googleImgUrl={props.googleImgUrl}
                    onAppleSsoClick={onSsoClickDecorator(
                        props.onAppleSsoClick, setNonFieldErrors,
                        'An error occurred. Please try again later.')}
                    googleSsoButtonEnabled={props.googleSsoButtonEnabled}
                    onGoogleSsoClick={props.onGoogleSsoClick}
                    isSignUp={true}
                    appleSsoEnabled={true}
                />
            )}
        </div>
    );
};

const requiredIfNotSso = (expectedType) => {
    return (props, propName, componentName) => {
        if (!props.isSso) {
            const propType = typeof props[propName];
            if (props[propName] === undefined || propType !== expectedType) {
                return new Error(
                    `"${propName}" must be of type "${expectedType}" not "${propType}" for non-sso "${componentName}".`
                );
            }
        }
    };
};

SignUpForm.propTypes = {
    submitUrl: PropTypes.string.isRequired,
    onSuccessfulSubmit: PropTypes.func.isRequired,
    showEmailOptIn: PropTypes.bool.isRequired,
    localizedTOS: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    subtext: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    submitButtonText: PropTypes.string.isRequired,
    onGoogleSsoClick: requiredIfNotSso('function'),
    googleSsoButtonEnabled: PropTypes.bool.isRequired,
    onAppleSsoClick: requiredIfNotSso('function'),
    appleImgUrl: requiredIfNotSso('string'),
    googleImgUrl: requiredIfNotSso('string'),
    isSso: PropTypes.bool, // true if the SignUpForm is part of an SSO auth flow. otherwise false.
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    ssoErrors: PropTypes.array,
};

SignUpForm.defaultProps = {
    isSso: false,
    subtext: '',
};

export {SignUpForm};
