import React, {useState} from 'react';
import $ from 'jquery';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import FormErrors from './form_errors.jsx';
import SubmitButton from './submit_button.jsx';

const LinkSsoForm = (props) => {
    const currLocation = useLocation();
    const [error, setError] = useState('');
    const submitButtonId = 'submit-link-sso';
    const cancelButtonId = 'cancel-link-sso';

    const onClickSubmit = (e) => {
        e.preventDefault();
        return $.post({
            url: `${props.submitUrl}${currLocation.search}`,
            contentType: 'json',
        }).done((data) => {
            window.location.href = data.url;
        }).fail(() => {
            setError('An error has occurred, please try again');
        });
    };


    return (
        <div>
            <form className='create-account-form' onSubmit={(e) => e.preventDefault()}>
                <h1>{props.title}</h1>
                <br />
                <p id='existing-account-warning'>
                    {props.subtext}
                </p>
                <FormErrors errors={error} />

                <SubmitButton
                    buttonId={cancelButtonId}
                    onClick={props.onClickBack}
                    className={'sd-button mod-button-wide vertical-spaced-button'}
                >
                    {props.cancelButtonText}
                </SubmitButton>
                <SubmitButton
                    buttonId={submitButtonId}
                    onClick={onClickSubmit}
                    className={'sd-secondary-button mod-button-wide vertical-spaced-button'}
                >
                    {props.submitButtonText}
                </SubmitButton>
            </form>
        </div>
    );
};


LinkSsoForm.propTypes = {
    onClickBack: PropTypes.func.isRequired,
    title: PropTypes.string,
    subtext: PropTypes.string,
    submitUrl: PropTypes.string,
    submitButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
};

LinkSsoForm.defaultProps = {};

export default LinkSsoForm;

