import React from 'react';
import PropTypes from 'prop-types';
import LinkSsoForm from './link_sso_form.jsx';
import {SignUpFooter} from './signup_footer.jsx';

const LinkGoogleSsoForm = (props) => {
    return (
        <div className='auth-narrow'>
            <div className='sd-card sd-elevation-1 auth-card'>
                <LinkSsoForm
                    onClickBack={props.onClickBack}
                    title='Are you sure?'
                    subtext={'That email is associated with an active 23andMe account.' +
                            ' If you sign in with Google, all future sign-ins will have' +
                            ' to be done this way too.' +
                            ' That means you’ll authenticate through Google instead of' +
                            ' entering your password.'}
                    submitUrl='/signup_with_google/'
                    submitButtonText='Sign-in with Google'
                    cancelButtonText='I’ll keep my password'
                />
            </div>
            <SignUpFooter />
        </div>
    );
};


LinkGoogleSsoForm.propTypes = {
    onClickBack: PropTypes.func.isRequired,
};

LinkGoogleSsoForm.defaultProps = {};

export default LinkGoogleSsoForm;

