import React, {useState} from 'react';
import PropTypes from 'prop-types';
import SubmitButton from './submit_button.jsx';
import FormErrors from './form_errors.jsx';
import {onSsoClickDecorator} from '../utils.jsx';

const SsoSignin = (props) => {
    const [error, setError] = useState();

    const getOnclick = () => {
        if (props.ssoProvider === 'Google') {
            return props.onClickCta;
        }
        return onSsoClickDecorator(
            props.onClickCta, setError,
            'An error occurred. Please try again later.');
    };

    return (
        <>
            <h1>Sign in with your {props.ssoProvider} account</h1>
            <p className='mod-last-p'>
                You may have linked your 23andMe account with {props.ssoProvider}.
                If that is not the case you can{' '}
                <button
                    className='sd-button-anchor sd-link'
                    onClick={props.onClickBack}
                >
                    sign in with your email and password
                </button>
                .
            </p>
            <FormErrors
                errors={error}
                classes='auth-error non-field-error'
            />
            <FormErrors
                errors={props.ssoErrors}
                classes='auth-error non-field-error'
            />
            <SubmitButton
                buttonId='google-sign-in-button'
                enabled={props.ssoProvider === 'Apple' || props.googleSsoButtonEnabled}
                onClick={getOnclick()}
                className='sd-button mod-button-outlined mod-button-wide'
                type='button'
            >
                <img
                    id='sso-sign-in-button-logo'
                    src={props.buttonImgUrl}
                    alt=''
                    height='24px'
                    width='24px'
                />
                Sign in with {props.ssoProvider}
            </SubmitButton>
            <p id='sso-disclaimer'>
                23andMe does not share any of your personal information,{' '}
                including your genetic results, with {props.ssoProvider}
            </p>
        </>
    );
};

SsoSignin.propTypes = {
    onClickCta: PropTypes.func.isRequired,
    onClickBack: PropTypes.func.isRequired,
    buttonImgUrl: PropTypes.string.isRequired,
    ssoProvider: PropTypes.string.isRequired,
    ssoErrors: PropTypes.array,
    googleSsoButtonEnabled: PropTypes.bool,
};

export {SsoSignin};
