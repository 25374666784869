import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';

const FormTextInput = (props) => {
    const inputId = `id_${props.name}`;
    const [hasValue, setHasValue] = useState(false);
    const inputClass = `sd-form-input ${props.isInvalid ? 'mod-invalid' : ''}`;
    const inputEle = useRef(null);

    useEffect(() => {
        if (props.autofocus) {
            inputEle.current.focus();
        }
    }, []);

    return (
        <div className={`sd-form-field mod-label-inside ${hasValue ? 'mod-active' : ''}`}>
            <label htmlFor={inputId} className='sd-form-label'>{props.label}</label>
            <input
                ref={inputEle}
                type={props.type}
                name={props.name}
                required
                id={inputId}
                className={inputClass}
                defaultValue={props.defaultValue}
                data-mdv-id={props.mdvId}
                onChange={(e) => {
                    setHasValue(e.target.value !== '');
                    if (props.onChange) {
                        props.onChange(e);
                    }
                }}
                autoComplete={props.autocomplete ? 'on' : 'off'}
            />
        </div>
    );
};


FormTextInput.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    label: PropTypes.string.isRequired,
    isInvalid: PropTypes.bool, // if true, apply error styling
    onChange: PropTypes.func,
    defaultValue: PropTypes.string,
    mdvId: PropTypes.string,
    autofocus: PropTypes.bool,
    autocomplete: PropTypes.bool,
};

FormTextInput.defaultProps = {
    type: 'text',
    autofocus: false,
    isInvalid: false,
    defaultValue: '',
    autocomplete: true,
};

export default FormTextInput;
