import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import authRoutes from '../auth_routes';
import PropTypes from 'prop-types';

const SignInLink = (props) => {
    const currLocation = useLocation();
    return (
        <p className={`${props.styling}`}>
            Already have an account?&nbsp;
            <Link
                to={{pathname: authRoutes.AUTH_ROUTES.SIGN_IN, search: currLocation.search}}
                data-mdv-id='auth-signup-signin-link-desktop'
            >
                Sign in
            </Link>
        </p>
    );
};

const SignUpFooter = () => {
    return <section><SignInLink /></section>;
};

SignInLink.propTypes = {
    styling: PropTypes.string,
};

SignInLink.defaultProps = {
    styling: 'auth-shorttext auth-subcard',
};

export {SignUpFooter, SignInLink};
