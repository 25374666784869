import React from 'react';
import PropTypes from 'prop-types';

const FormErrors = (props) => {
    let errors = props.errors;
    if (!errors) {
        return null;
    } else if (typeof errors === 'string') {
        errors = [errors];
    }
    return errors
        .filter((e) => e)
        .map((error, index) => (
            <p
                key={`${props.id}_${index}`}
                data-mdv-id={props.isMdv ? 'auth-error' : ''}
                className={props.classes}
                dangerouslySetInnerHTML={{__html: error}}
            >
            </p>
        ));
};

FormErrors.propTypes = {
    id: PropTypes.string,
    errors: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string,
    ]),
    classes: PropTypes.string,
    isMdv: PropTypes.bool,
};

FormErrors.defaultProps = {
    errors: [],
    classes: 'auth-error',
    isMdv: false,
};

export default FormErrors;
