import React, {useState} from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';

const APP_STORE_RATING = '4.8';
const GOOGLE_PLAY_RATING = '4+';

const AppBanner = (props) => {
    const isIosDevice = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
    const isAndroidDevice = /(Android)/g.test(navigator.userAgent);
    const [showBanner, setShowBanner] = useState(isIosDevice || isAndroidDevice);

    let ratingText;
    const lastStarClassList = ['last-star'];
    const bannerClassList = ['app-banner'];
    if (isIosDevice) {
        ratingText = APP_STORE_RATING;
        bannerClassList.push('app-store');
    } else if (isAndroidDevice) {
        ratingText = GOOGLE_PLAY_RATING;
        bannerClassList.push('google-play');
        lastStarClassList.push('clipped-star');
    }
    if (!showBanner) {
        bannerClassList.push('collapsed');
    }

    const hideBanner = () => {
        setShowBanner(false);
    };

    const bannerClasses = bannerClassList.join(' ');
    const lastStarClasses = lastStarClassList.join(' ');

    const fourStars = range(0, 4).map((idx) => (
        <svg
            className='svg-star'
            width='24'
            height='24'
            key={idx}
        >
            <use xlinkHref={`${props.starIconUrl}#icon`} />
        </svg>
    ));

    return (
        <div className={bannerClasses}>
            <button
                className='unbutton close-banner js-close-banner'
                onClick={hideBanner}
                aria-label='Close'
            >
                <svg className='svg-close' width='32' height='32'>
                    <use xlinkHref={`${props.closeIconUrl}#icon`} />
                </svg>
            </button>
            <div className='app-banner-container'>
                <h1 className='title'>
                    Register faster
                    <br className='line-break' />
                    with our app
                </h1>
                <div className='app-banner-rating'>
                    <div className='rating-stars'>
                        {fourStars}
                        <div className={lastStarClasses}>
                            <svg
                                className='svg-star under'
                                width='24'
                                height='24'
                            >
                                <use xlinkHref={`${props.starIconUrl}#icon`} />
                            </svg>
                            <svg
                                className='svg-star js-clipped-star'
                                width='24'
                                height='24'
                            >
                                <use xlinkHref={`${props.starIconUrl}#icon`} />
                            </svg>
                        </div>
                    </div>
                    <div className='rating-value'>
                        <span className='rating-number'>{ratingText}</span>
                        <span className='rating-label'>RATING</span>
                    </div>
                </div>
                <div className='app-banner-badge'>
                    <div className='badge-app-store u-hide'>
                        <a
                            href='https://itunes.apple.com/us/app/23andme/id952516687?mt=8'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <img src={props.appStoreUrlIconUrl} alt='Download on the App Store' />
                        </a>
                    </div>
                    <div className='badge-google-play u-hide'>
                        <a
                            href='https://play.google.com/store/apps/details?id=com.twentythreeandme.app&hl=en'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <img src={props.googlePlayStoreIconUrl} alt='Get it on Google Play' />
                        </a>
                    </div>
                </div>
                <div className='continue'>or continue below</div>
            </div>
        </div>
    );
};

AppBanner.propTypes = {
    starIconUrl: PropTypes.string.isRequired,
    closeIconUrl: PropTypes.string.isRequired,
    appStoreUrlIconUrl: PropTypes.string.isRequired,
    googlePlayStoreIconUrl: PropTypes.string.isRequired,
};

export {AppBanner, GOOGLE_PLAY_RATING, APP_STORE_RATING};
