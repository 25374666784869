import React from 'react';
import PropTypes from 'prop-types';

const SimpleSigninFooter = (props) => {
    return (
        <section>
            <p className='auth-shorttext auth-subcard'>
                <a id={props.linkId} href={props.linkHref}>
                    Sign in to your account
                </a>
            </p>
        </section>
    );
};

SimpleSigninFooter.propTypes = {
    linkId: PropTypes.string,
    linkHref: PropTypes.string,
};


SimpleSigninFooter.defaultProps = {
    linkHref: '/login/',
};

export {SimpleSigninFooter};
