import React from 'react';
import PropTypes from 'prop-types';
import {SignUpForm} from './signup_form.jsx';

const GoogleSignup = (props) => {
    return (
        <div className='auth-narrow'>
            <div className='sd-card sd-elevation-1 auth-card' >
                <SignUpForm
                    title='Please provide your first name, last name, and date of birth'
                    firstName={props.firstName}
                    lastName={props.lastName}
                    onSuccessfulSubmit={props.onSuccessfulSubmit}
                    localizedTOS={props.localizedTOS}
                    showEmailOptIn={props.showEmailOptIn}
                    submitUrl='/signup_with_google/'
                    submitButtonText='Continue signing up'
                    isSso={true}
                />
            </div>
        </div>
    );
};


GoogleSignup.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    onSuccessfulSubmit: PropTypes.func.isRequired,
    showEmailOptIn: PropTypes.bool.isRequired,
    localizedTOS: PropTypes.string.isRequired,
};

GoogleSignup.defaultProps = {};

export {GoogleSignup};
