import React from 'react';
import {getEmbeddedNextSearchParam} from './utils.jsx';


const DEFAULT_TYPE = {
    'title': 'Create an account',
    'subtext': '',
};

// Definitions for each of these sources can be found in product repo
// busobjects/account.py under the BOAccount.SOURCE constant
const ACCOUNT_TYPES = {
    '2': { // claims flow
        'title': 'Register your kit',
        'subtext': 'Create an account to register and link your kit.',
    },
    '3': { // demo signup
        'title': 'Start exploring',
        'subtext': <>Use your account to get a taste of what 23andMe can tell you about your
            DNA - and yourself.</>,
    },
    '5': { // out of network invitation
        'title': <>Create an account or <a href='/login/'>sign in</a> to view reports</>,
        'subtext': <>Once your account is created, you’ll be able to see the reports that were
            shared with you.</>,
    },
    '7': { // API
        'title': 'Create a developer account',
        'subtext': <>With your account, you can develop apps and see what it&#39s like to get
            genotyped. If you buy a kit to get genotyped, you can get your own genetic analysis,
            and apps will use your real data.</>,
    },
    '8': { // research enrollment
        'title': 'Create an account',
        'subtext': <>We will use this information to set up your 23andMe account and
            communicate with you about this study.</>,
    },
    '17': { // store subscription
        title: 'Let’s get started by setting up your 23andMe account',
        subtext: '',
    },
    '102': { // external data input
        'title': 'Sign up for free',
        'subtext': <><strong>Welcome, Ancestry.com customer.</strong>
            After you create your account, you will be
            prompted to upload your genetic raw data file.
            Discover23 file upload service is no longer available.
            You may create a new 23andMe account below but will not
            have access to the Discover23 experience.
        </>,
    },
};

/**
 * Services can redirect the user to signup.
 * The source can live in the url, or in the url embedded in the next get param.
 * This function takes a URLSearchParam and returns the account type if the search param is valid.
 * Returns undefined if there is no next or if the parsing fails.
 */
const extractSourceFromSearchParam = (searchParam) => {
    if (!searchParam) {
        return;
    }
    const source = searchParam.get('source');
    if (!source || !ACCOUNT_TYPES[source]) {
        return;
    }
    return ACCOUNT_TYPES[source];
};

/**
 * Retrieve the signup page title and subtext.
 * @returns {{subtext: string, title: string}}
 */
const getSource = () => {
    // order here is important
    return (extractSourceFromSearchParam(getEmbeddedNextSearchParam()) ||
        extractSourceFromSearchParam(new URLSearchParams(window.location.search)) ||
        DEFAULT_TYPE);
};

export {
    ACCOUNT_TYPES,
    DEFAULT_TYPE,
    getSource,
};
