/**
 * Take a function like onAppleSsoClick and decorates it to handle the case where
 * the sso token api call fails.
 * @param onSsoClick func like onAppleSsoClick
 * @param setErrorFunc func like setNonFieldErrors
 * @param error error message to pass to setErrorFunc
 */
export const onSsoClickDecorator = (onSsoClick, setErrorFunc, error) => {
    if (typeof onSsoClick !== 'function') {
        return null;
    }
    if (typeof setErrorFunc !== 'function') {
        return null;
    }

    return () => {
        return onSsoClick().catch((e) => {
            setErrorFunc(error);
            throw e;
        });
    };
};

const parseUrl = (url) => {
    try {
        return new URL(url);
    } catch (e) {
        if (!(e instanceof TypeError)) {
            throw e;
        }
    }
};

/**
 * The next GET param has an embedded URL. This function parses and returns a URL object
 * or undefined for the embedded URL.
 */
export const getNextUrl = (search=null) => {
    if (!search) {
        search = window.location.search;
    }
    const searchParams = new URLSearchParams(search);
    const url = parseUrl(searchParams.get('next'));
    if (!url || window.location.hostname != url.hostname) {
        return;
    }
    return url;
};

export const getEmbeddedNextSearchParam = (search = null) => {
    const url = getNextUrl(search);
    if (url) {
        return url.searchParams;
    }
};
