import React, {useState, useLayoutEffect} from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import {useLocation} from 'react-router-dom';
import FormTextInput from './form_text_input.jsx';
import FormErrors from './form_errors.jsx';
import SubmitButton from './submit_button.jsx';
import {SsoButtons} from './sso_buttons.jsx';
import {onSsoClickDecorator} from '../utils.jsx';


const SignInForm = (props) => {
    const currLocation = useLocation();
    const [preLoginErrors, setPreLoginErrors] = useState();
    const [nonFieldErrors, setNonFieldErrors] = useState();
    const [emailErrors, setEmailErrors] = useState();
    const [passwordErrors, setPasswordErrors] = useState();
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const searchParams = new URLSearchParams(currLocation.search);

    useLayoutEffect(() => {
        $.get({
            url: '/login/',
        }).done((data) => {
            if (data.preLoginErrors) {
                setPreLoginErrors(data.preLoginErrors);
            }
        });
    }, []);

    const hasErrors = (errors) => {
        return errors && errors.length > 0;
    };

    const validateForSubmit = () => {
        let isValidSubmitState = true;
        [
            {value: userEmail, errorFn: setEmailErrors},
            {value: userPassword, errorFn: setPasswordErrors},
        ].forEach(({value, errorFn}) => {
            if (!value) {
                errorFn('This field is required.');
                isValidSubmitState = false;
            }
        });
        return isValidSubmitState;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setPreLoginErrors(null);
        if (!validateForSubmit()) {
            return false;
        }
        const redirectUri = searchParams.get('next') || searchParams.get('redirect_uri');
        const payload = {
            username: userEmail,
            password: userPassword,
            next: redirectUri,
        };
        return $.post({
            url: '/login/',
            data: payload,
        }).done((data) => {
            props.onSuccessfulSubmit(data);
        }).fail((data) => {
            if (!data.responseJSON) {
                return;
            }
            if (data.responseJSON.preLoginErrors) {
                setPreLoginErrors(data.responseJSON.preLoginErrors);
            }
            if (data.responseJSON.errors) {
                const errors = data.responseJSON.errors;
                setEmailErrors(errors.username);
                setPasswordErrors(errors.password);
                setNonFieldErrors(errors.__all__);
            }
        });
    };
    return (
        <div id='sign-in-form'>
            <h1>Sign in</h1>
            <form
                method='post'
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <FormErrors
                    errors={preLoginErrors}
                    classes='auth-error non-field-error'
                    isMdv={true}
                />
                <FormErrors
                    errors={nonFieldErrors}
                    classes='auth-error non-field-error'
                    isMdv={true}
                />
                <FormTextInput
                    autofocus={true}
                    label='Email address'
                    name='username'
                    type='email'
                    value={userEmail}
                    isInvalid={hasErrors(emailErrors)}
                    mdvId='auth-email'
                    onChange={(e) => {
                        setUserEmail(e.target.value);
                        setEmailErrors([]);
                    }}
                />
                <FormErrors
                    errors={emailErrors}
                    classes='auth-error'
                    isMdv={true}
                />

                <FormTextInput
                    label='Password'
                    name='password'
                    type={showPassword ? 'text': 'password'}
                    value={userPassword}
                    isInvalid={hasErrors(passwordErrors)}
                    mdvId='auth-password'
                    onChange={
                        (e) => {
                            setUserPassword(e.target.value);
                            setPasswordErrors([]);
                        }
                    }
                />
                <p>
                    <label htmlFor='show-password'>
                        <input id='show-password' className='show-password-checkbox' type='checkbox' onChange={(e) => {
                            setShowPassword(e.target.checked);
                        }} /> Show password
                    </label>
                </p>
                <FormErrors
                    errors={passwordErrors}
                    classes='auth-error'
                    isMdv={true}
                />
                <SubmitButton
                    buttonId='sign-in-button'
                    onClick={onSubmit}
                    mdvId='auth-signin'
                    gtmTrackClick='auth_sign_in_button'
                    className='sd-button auth-login-submit mod-button-wide'
                >
                    Sign in
                </SubmitButton>
            </form>
            <p className='auth-shorttext mod-forgot'>
                <button
                    id='forgot-password'
                    onClick={props.onForgotPasswordClick}
                    className='sd-button-anchor sd-link'
                    type='button'
                >
                    Reset password
                </button>
            </p>
            <SsoButtons
                appleImgUrl={props.appleImgUrl}
                googleImgUrl={props.googleImgUrl}
                onAppleSsoClick={onSsoClickDecorator(
                    props.onAppleSsoClick, setNonFieldErrors,
                    'An error occurred. Please try again later.')}
                onGoogleSsoClick={props.onGoogleSsoClick}
                googleSsoButtonEnabled={props.googleSsoButtonEnabled}
                ssoErrors={props.ssoErrors}
            />
        </div>
    );
};

SignInForm.propTypes = {
    onAppleSsoClick: PropTypes.func.isRequired,
    onGoogleSsoClick: PropTypes.func.isRequired,
    googleSsoButtonEnabled: PropTypes.bool.isRequired,
    googleImgUrl: PropTypes.string.isRequired,
    appleImgUrl: PropTypes.string.isRequired,
    onSuccessfulSubmit: PropTypes.func.isRequired,
    onForgotPasswordClick: PropTypes.func.isRequired,
    ssoErrors: PropTypes.array,
};

SignInForm.defaultProps = {};

export default SignInForm;
