import React from 'react';
import PropTypes from 'prop-types';

const PasswordResetVerify = (props) => {
    if (!props.email) {
        props.anonUserRedirect();
    }
    return (
        <>
            <div>
                <h1>Reset your password</h1>
                <p>
                    Password reset instructions have been sent to you at <strong>{props.email}</strong>.
                    Please follow the instructions in the email to create a new password. For your security,
                    the link will only be valid for 1 hour.
                </p>
                <p className='mod-last-p'>
                    For further questions,&nbsp;
                    <a
                        href='https://customercare.23andme.com/hc/en-us/articles/202907580/'
                        title='visit this page'
                    >
                        visit this page
                    </a>
                    .
                </p>
            </div>
        </>
    );
};

PasswordResetVerify.propTypes = {
    email: PropTypes.string,
    anonUserRedirect: PropTypes.func.isRequired,
};

export {PasswordResetVerify};
