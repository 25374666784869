import React, {useState} from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import 'styledot/forms';
import FormTextInput from './form_text_input.jsx';
import SubmitButton from './submit_button.jsx';
import FormErrors from './form_errors.jsx';

const PasswordReset = (props) => {
    const [email, setEmail] = useState();
    const [emailErrors, setEmailErrors] = useState();
    const [nonFieldErrors, setNonFieldErrors] = useState();
    const hasErrors = (errors) => {
        return errors && errors.length > 0;
    };
    const onSubmit = (e) => {
        e.preventDefault();
        if (!email) {
            setEmailErrors('This field is required.');
            return false;
        }
        return $.post({
            url: '/forgot/',
            data: {email},
        }).then((data) => {
            props.onSuccessfulSubmit(data);
        }).fail((data) => {
            if (!data.responseJSON) {
                setNonFieldErrors('An error occurred.');
                return;
            }
            if (data.responseJSON.errors) {
                const errors = data.responseJSON.errors;
                setEmailErrors(errors.email);
                setNonFieldErrors(errors.__all__);
            }
        });
    };

    return (
        <>
            <h1>Reset your password</h1>
            <p className='mod-last-p'>
                Enter the email address associated with your account and we’ll send you an email with instructions
                and a link to create a new password.
            </p>
            <form>
                <FormErrors errors={nonFieldErrors} />
                <FormTextInput
                    autofocus={true}
                    name='email'
                    label='Email'
                    type='email'
                    placeholder='Email'
                    isInvalid={hasErrors(emailErrors)}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                />
                <FormErrors errors={emailErrors} />
                <SubmitButton
                    onClick={onSubmit}
                    buttonId='auth-forgot'
                    className='sd-button mod-button-wide js-auth-forgot vertical-spaced-button'
                >
                    Send reset email
                </SubmitButton>
            </form>
        </>
    );
};


PasswordReset.propTypes = {
    onSuccessfulSubmit: PropTypes.func.isRequired,
};

export {PasswordReset};
