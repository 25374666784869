import $ from 'jquery';

const setup = ({csrfToken}) => {
    // Setup csrf for AJAX with Django
    // https://docs.djangoproject.com/en/3.0/ref/csrf/
    $.ajaxSetup({
        beforeSend: function(xhr, settings) {
            xhr.setRequestHeader('X-CSRFToken', csrfToken);
        },
    });
};

export {setup};
