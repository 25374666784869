const AUTH_ROUTES = {
    SIGN_IN: '/login/',
    SIGN_UP: '/signup/',
    GOOGLE_SSO: {
        LINK: '/google_link/',
        SIGN_UP: '/google_signup/',
        SIGN_IN: '/google_login/',
    },
    APPLE_SSO: {
        LINK: '/apple_link/',
        SIGN_UP: '/apple_signup/',
        SIGN_IN: '/apple_login/',
        RELAY_EMAIL_WARNING: '/apple_relay_email_warning/',
    },
    VERIFY: '/verify/',
    SSO_MUTEX: '/sso_mutex/',
    MFA_TOTP: '/mfa_totp/',
    FORGOT: '/forgot/',
    COOKIES_ERROR: '/cookies_error/',
    PASSWORD_RESET: '/password_reset/',
    MFA_RESET: '/mfa_reset/',
    MFA_RESET_SENT: '/mfa_reset_sent/',
    MFA_RESET_SIGNIN: '/mfa_reset_login/',
    MFA_RESET_BAD_LINK: '/mfa_reset_failed/',
    FORGOT_VERIFY: '/password_reset_done/',
    CONFIRM_ACCOUNT_SIGNIN: '/confirm_account_signin/',
};

const getObjectValues = (obj, values) => {
    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'object') {
            getObjectValues(obj[key], values);
        } else {
            values.add(obj[key]);
        }
    });
    return values;
};


const routes = getObjectValues(AUTH_ROUTES, new Set());


const redirectToRoute = (history, target, getParams=null) => {
    if (routes.has(target)) {
        if (!getParams) {
            history.push(`${target}${history.location.search}`);
        } else {
            history.push({
                pathname: target,
                search: getParams,
            });
        }
    } else {
        redirectToLogin(history);
        throw new Error(`Undefined route ${target}`);
    }
};


const redirectToLogin = (history) => {
    redirectToRoute(history, AUTH_ROUTES.SIGN_IN);
};

const toExport = {
    AUTH_ROUTES,
    redirectToRoute,
    redirectToLogin,
    routes,
};

export default toExport;
