import React from 'react';
import PropTypes from 'prop-types';
import SubmitButton from './submit_button.jsx';

const AppleSsoRelayEmailWarning = (props) => {
    return (
        <div>
            <div>
                <h1>Keep in mind:</h1>
                <p>
                    From now on, you&apos;ll have to select &ldquo;Sign in with Apple&rdquo; to access your 23andMe
                    account.
                    Please note, if you choose to use a randomized email generated by Apple, you&apos;ll need to
                    provide this same email address to Customer Care if you contact them for help.
                    We recommend keeping a copy of this email somewhere safe.
                </p>
                <p className='mod-last-p'>
                    To remove this connection from your 23andMe account,&nbsp;
                    <a href='https://customercare.23andme.com' title='Contact Customer Care'>
                        contact Customer Care
                    </a>.
                </p>
            </div>
            <SubmitButton
                enabled={true}
                buttonId='relay-email-warning-next'
                onClick={props.onClickCta}
                className='sd-button mod-button-wide vertical-spaced-button'
            >
                Continue
            </SubmitButton>
        </div>
    );
};

AppleSsoRelayEmailWarning.propTypes = {
    onClickCta: PropTypes.func.isRequired,
};

export {AppleSsoRelayEmailWarning};
