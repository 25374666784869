import React from 'react';
import ReactDOM from 'react-dom';
import {setup} from './index.js';
import {AuthMainWrapper} from './components/auth_main.jsx';

const renderAuth = ({
    googleImgUrl,
    googleClientId,
    appleImgUrl,
    appleClientId,
    showEmailOptIn,
    localizedTOS,
    showAppDownload,
    showKitImage,
    starIconUrl,
    closeIconUrl,
    appStoreImgUrl,
    googlePlayImgUrl,
    kitImgUrl,
    csrfToken,
}) => {
    setup({csrfToken});

    ReactDOM.render(
        <AuthMainWrapper
            googleImgUrl={googleImgUrl}
            googleClientId={googleClientId}
            appleImgUrl={appleImgUrl}
            appleClientId={appleClientId}
            showEmailOptIn={showEmailOptIn}
            localizedTOS={localizedTOS}
            showAppDownload={showAppDownload}
            showKitImage={showKitImage}
            starIconUrl={starIconUrl}
            closeIconUrl={closeIconUrl}
            appStoreImgUrl={appStoreImgUrl}
            googlePlayImgUrl={googlePlayImgUrl}
            kitImgUrl={kitImgUrl}
            csrfToken={csrfToken}
        />,
        document.getElementById('auth-main')
    );
};

const ttamAuth = {renderAuth};
window.ttam_auth = ttamAuth;
