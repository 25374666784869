import React from 'react';
import {Link, useLocation} from 'react-router-dom';

const SignInFooter = () => {
    const currLocation = useLocation();

    return (
        <section>
            <p className='auth-shorttext auth-subcard'>
                New to 23andMe?&nbsp;
                <a
                    className=''
                    href="https://store.23andme.com/?kit=1&utm_source=23andme&utm_medium=website&utm_campaign=logout_login_page&utm_content=23c_product" // eslint-disable-line
                >
                    Buy a kit
                </a> or&nbsp;
                <Link to={{pathname: '/signup/', search: currLocation.search}}>
                    Sign up
                </Link>
            </p>
        </section>
    );
};

export {SignInFooter};
