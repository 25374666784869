import React from 'react';
import {Link} from 'react-router-dom';
import authRoutes from '../auth_routes';


const MfaResetSent = (props) => {
    return (
        <div className='auth-narrow'>
            <div className='sd-card sd-elevation-1 auth-card'>
                <h1>
                    Your link has been emailed
                </h1>
                <p>
                    We sent a one-click login link to your account email address.
                    Once you click the link in this email, you will be prompted to enter
                    your login credentials to access your account.
                    This link will expire in 15 minutes.
                </p>
                <p>
                    Be sure to check your spam or junk folder if you haven&apos;t received it.
                </p>
                <p>
                    Having trouble? <Link to={{pathname: authRoutes.AUTH_ROUTES.MFA_RESET}}>Resend link
                    </Link> or <a href='https://customercare.23andme.com' title='Contact Customer Care'>
                    contact Customer Care</a>.
                </p>
            </div>
        </div>
    );
};

MfaResetSent.propTypes = {};

MfaResetSent.defaultProps = {};

export default MfaResetSent;
