const initAppleSso = (appleClientId) => {
    AppleID.auth.init({
        clientId: appleClientId,
        scope: 'name email',
        usePopup: true,
        redirectURI: `${window.location.origin}/apple_token/`,
        state: '',
    });
};

const startSignInWithApple = () => {
    return AppleID.auth.signIn();
};

const initGoogleSso = (googleClientId, signinCallback, errorCallback) => {
    return google.accounts.oauth2.initCodeClient({
        client_id: googleClientId,
        scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
        ux_mode: 'popup',
        callback: signinCallback,
        error_callback: errorCallback,
        include_granted_scopes: false,
    });
};

const toExport = {
    initAppleSso, startSignInWithApple, initGoogleSso,
};

export default toExport;
