import React from 'react';
import PropTypes from 'prop-types';

const CookiesDisabledPage = (props) => {
    if (navigator.cookieEnabled) {
        props.onCookiesEnabled();
    }
    return (
        <div>
            <h1>Cookies Disabled</h1>
            <p className='mod-last-p'>
                Try enabling third party cookies to sign in to account. If
                you still can’t sign in,
                visit the <a href='https://customercare.23andme.com/hc/en-us/articles/360045209474'>
                    Help Center</a>.
            </p>
        </div>
    );
};

CookiesDisabledPage.propTypes = {
    onCookiesEnabled: PropTypes.func.isRequired,
};

export {CookiesDisabledPage};
