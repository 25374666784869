import React from 'react';
import PropTypes from 'prop-types';
import SubmitButton from './submit_button.jsx';
import FormErrors from './form_errors.jsx';

const SsoButtons = (props) => {
    const textBase = props.isSignUp ? 'Sign up with' : 'Sign in with';
    return (
        <div>
            <div>
                <hr className='sd-rule'></hr>
                <FormErrors
                    errors={props.ssoErrors}
                    classes='auth-error non-field-error'
                />
                <SubmitButton
                    buttonId='google-sign-in-button'
                    enabled={props.googleSsoButtonEnabled}
                    onClick={props.onGoogleSsoClick}
                    className='sd-button mod-button-outlined mod-button-wide'
                    type='button'
                >
                    <img
                        id='google-sign-in-button-logo'
                        src={props.googleImgUrl}
                        alt=''
                        height='24px'
                        width='24px'
                    />
                    {`${textBase} Google`}
                </SubmitButton>
            </div>
            <div>
                <SubmitButton
                    buttonId='apple-sign-in-button'
                    onClick={props.onAppleSsoClick}
                    className='sd-button mod-button-outlined mod-button-wide'
                    type='button'
                >
                    <img
                        id='apple-sign-in-button-logo'
                        src={props.appleImgUrl}
                        alt=''
                        height='24px'
                        width='24px'
                    />
                    {`${textBase} Apple`}
                </SubmitButton>
                <p id='sso-disclaimer'>
                    23andMe does not share any of your personal information with Google or Apple,&nbsp;
                    including your genetic results
                </p>
            </div>
        </div>
    );
};

SsoButtons.propTypes = {
    appleImgUrl: PropTypes.string.isRequired,
    googleImgUrl: PropTypes.string.isRequired,
    onGoogleSsoClick: PropTypes.func.isRequired,
    googleSsoButtonEnabled: PropTypes.bool.isRequired,
    onAppleSsoClick: PropTypes.func.isRequired,
    isSignUp: PropTypes.bool,
    ssoErrors: PropTypes.array,
};

SsoButtons.defaultProps = {
    isSignUp: false,
};

export {SsoButtons};
