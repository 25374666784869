import React from 'react';
import PropTypes from 'prop-types';
import SubmitButton from './submit_button.jsx';

/**
 * This is the page to prevent the user from trying to link with multiple
 * SSO providers.
 */
const SsoMutexPage = (props) => {
    if (!props.existingSsoProvider) {
        props.anonUserRedirect();
    }
    return (
        <div>
            <div>
                <h1>Looks like you&apos;ve signed up before</h1>
                <p className='mod-last-p'>
                    You previously signed up for a 23andMe account with {props.existingSsoProvider}
                </p>
            </div>
            <SubmitButton
                enabled={true}
                buttonId='sso-error-back'
                onClick={props.onClickCta}
                className='sd-button mod-button-wide'
            >
                Continue with {props.existingSsoProvider} sign-in
            </SubmitButton>
        </div>
    );
};

SsoMutexPage.propTypes = {
    existingSsoProvider: PropTypes.string,
    onClickCta: PropTypes.func.isRequired,
    anonUserRedirect: PropTypes.func.isRequired,
};

export {SsoMutexPage};
