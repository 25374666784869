import React from 'react';
import PropTypes from 'prop-types';

const FormSelect = (props) => {
    let defaultOption = null;
    let defaultValue = null;
    let options = props.options;
    if (props.nameIsValue) {
        options = options.map((elem) => ({name: elem, value: elem}));
    }
    if (props.placeholder != null) {
        defaultOption = <option value='' disabled>{props.placeholder}</option>;
        defaultValue = '';
    }
    let selectClass = 'sd-form-select';
    if (props.isInvalid) {
        // TODO: I've always passed React classNames an array, what package am I missing?
        selectClass += ' mod-invalid';
    }
    return (
        <>
            <label htmlFor={props.id} className='visuallyhidden'>{props.label}</label>
            <select
                id={props.id}
                className={selectClass}
                required={props.required}
                onChange={props.onChange}
                onBlur={props.onChange}
                defaultValue={defaultValue}
            >
                {defaultOption}
                {
                    options.map(
                        (elem, index) => <option key={`${props.id}_${index}`}
                            value={elem.value}>{elem.name}</option>
                    )
                }
            </select>
        </>
    );
};


FormSelect.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.array, // [{name: "", value: ""}, ...] unless props.nameIsValue == true
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    isInvalid: PropTypes.bool, // if true, apply error styling

    // if true, assume props.options is an array of
    // strings and the name and value will both be set to that value
    nameIsValue: PropTypes.bool,
};

FormSelect.defaultProps = {
    type: 'text',
    isInvalid: false,
    placeholder: null,
    required: true,
    options: [],
    nameIsValue: false,
};

export default FormSelect;

